/* 헤더 */

.layer {
  width: 100%;
  height: 60px;
  background-image: linear-gradient(91deg, #e3416b 0%, #e45f48 100%);
  z-index: 9999;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}

.hide {
  opacity: 0;
}

header {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 60px;
  background-image: linear-gradient(91deg, #e3416b 0%, #e45f48 100%);
}

.h_container {
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_wrap {
  display: flex;
  column-gap: 40px;
}

.nav {
  padding: 10px;
  color: #fff2f1;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
}

.leng_wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.leng_wrap span {
  width: 1px;
  height: 10px;
  background-color: #fff;
}

.leng_wrap button {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  padding: 2px;
  font-size: 14px;
}

/* 모바일 */
.mobile_nav_wrap {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #111;
  overflow-x: auto;
}

.mobile_nav_wrap::-webkit-scrollbar {
  display: none;
}

.mobile_nav_wrap .nav {
  padding: 12px 20px;
  color: #fff2f1;
}

.mobile_nav_wrap .navActive {
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  padding: 12px 20px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
}

/* 푸터 */
.f_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #171717;
}

.f_container {
  width: 100%;
  max-width: 1300px;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  row-gap: 30px;
  column-gap: 20px;
}

.f_container p {
  width: 100%;
  color: #838383;
  font-size: 12px;
  margin-top: 14px;
  text-align: end;
}

.f_container a {
  font-size: 13px;
  color: #e3416b;
}

.f2_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.f2_wrap p {
  font-size: 12px;
  color: #838383;
}

.sns_wrap {
  display: flex;
  column-gap: 24px;
}

.icon {
  cursor: pointer;
}

.icon_active {
  display: none;
}

.icon:hover .icon_active {
  display: block;
}

.icon:hover .icon_none {
  display: none;
}

@media screen and (max-width: 600px) {
  .f_container {
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 20px;
  }

  .f2_wrap {
    align-items: flex-start;
  }

  .f_container p {
    text-align: start;
  }
}
