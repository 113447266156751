.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: linear-gradient(111deg, #e3416b 3%, #e45f48 100%);
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 1300px;
  padding: 160px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.title_wrap {
  z-index: 999;
  margin: 0;
  padding: 0;
}

.title {
  font-size: 40px;
  max-width: 880px;
  color: #fff;
  line-height: 1.25;
  white-space: pre-line;
}

.subTitle {
  font-size: 22px;
  padding: 0;
  margin: 0;
  color: #fff;
  font-weight: 500;
  white-space: pre-line;
  margin-bottom: 10px;
}

.p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 20px;
  max-width: 798px;
  white-space: pre-line;
}

.img_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.img4,
.img3,
.img2 {
  position: absolute;
}

.img4 {
  top: 140px;
  left: -160px;
}

.img3 {
  bottom: 0;
  left: 80px;
}

.img2 {
  top: 260px;
  left: 220px;
}

@media screen and (max-width: 1000px) {
  .img_wrap {
    margin-top: 80px;
  }

  .img4,
  .img3,
  .img2 {
    display: none;
  }

  .img1 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    min-height: 100%;
  }

  .container {
    padding: 140px 20px 40px;
  }

  .title {
    font-size: 26px;
  }

  .subTitle {
    font-size: 18px;
  }

  .p {
    font-size: 16px;
  }

  .img_wrap {
    margin-top: 30px;
  }
}
