.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1300px;
  padding: 160px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
  position: relative;
}

.title_wrap {
  z-index: 999;
  margin: 0;
  padding: 0;
}

.title {
  font-size: 40px;
  max-width: 700px;
  color: #fff;
  line-height: 1.25;
  white-space: pre-line;
  color: #555;
}

.p {
  font-size: 18px;
  color: #797979;
  margin-top: 20px;
  max-width: 600px;
  white-space: pre-line;
}

.btn {
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
  padding: 16px 40px;
  font-size: 16px;
  background-image: linear-gradient(97deg, #e3416b 0%, #e45f48 100%);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 30px;
  color: #fff;
  transition: 0.1s ease-in-out;
}

.hoverIcon {
  display: none;
}

.btn:hover {
  background: transparent;
  border: 1.5px solid #e3416b;
  color: #e3416b;
  box-shadow: none;
  font-weight: 500;
}

.btn:hover .noneIcon {
  display: none;
  width: 20px;
}

.btn:hover .hoverIcon {
  display: inline-block;
}

.img_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

@media screen and (max-width: 1400px) {
  .img2 {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 100px 20px 60px;
  }

  .img_wrap {
    margin-top: 80px;
    top: 0;
  }

  .img1 {
    width: 100%;
  }

  .mBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 14px;
    padding: 16px 0;
    font-size: 16px;
    background: transparent;
    border: 1.5px solid #e3416b;
    color: #e3416b;
    box-shadow: none;
    font-weight: 600;
    border-radius: 12px;
    margin-top: 40px;
  }

  .mBtn .hoverIcon {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    min-height: 100%;
  }

  .container {
    padding: 100px 20px 40px;
    align-items: flex-start;
  }

  .title {
    font-size: 26px;
  }

  .p {
    font-size: 16px;
  }

  .img_wrap {
    margin-top: 50px;
  }
}
