.wrapper {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 180px 0;
}

/* 타이틀 박스 */
.title_wrap {
  width: 100%;
  display: flex;
}

.title_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.title {
  font-size: 40px;
  max-width: 700px;
  color: #fff;
  line-height: 1.25;
  white-space: pre-line;
  color: #e3416b;
}

.p {
  font-size: 18px;
  color: #797979;
  margin-top: 20px;
  white-space: pre-line;
}

/* 컨텐츠 시작 */
.contents_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  margin-top: 60px;
}

.contents_container {
  overflow-x: auto;
  width: 100%;
  display: flex;
  padding: 0 20px;
  column-gap: 20px;
}

.contents_container::-webkit-scrollbar {
  display: none;
}

.contents_container li {
  display: flex;
  flex-direction: column;
}

.imgBox {
  width: 100%;
  min-width: 300px;
  background-color: #f5f5f5;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgBox img {
  width: 100%;
}

/* ////////////==============//////////// */
.concept_wrap {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}

.concept_wrap h1 {
  font-size: 18px;
  width: auto;
  color: #333;
}

.concept_wrap h1 span {
  color: #fff;
  background-image: linear-gradient(108deg, #e3416b 2%, #e45f48 100%);
  font-size: 11px;
  padding: 2px 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-weight: 400;
}

.concept_wrap p {
  color: #797979;
  margin-top: 4px;
  width: auto;
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 600px) {
  .container {
    padding: 100px 0 60px;
    align-items: flex-start;
  }

  .title {
    font-size: 26px;
  }

  .p {
    font-size: 16px;
  }

  .contents_wrap {
    margin-top: 40px;
  }

  .concept_wrap h1 {
    font-size: 16px;
  }

  .concept_wrap h1 span {
    font-size: 10px;
  }

  .concept_wrap p {
    font-size: 14px;
  }
}
