.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #252525;
  display: flex;
  justify-content: center;
  position: relative;
}

.container {
  width: 100%;
  max-width: 1300px;
  padding: 180px 20px 160px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title_wrap {
  z-index: 999;
}

.title {
  font-size: 40px;
  max-width: 880px;
  color: #fff;
  line-height: 1.25;
}

.subTitle {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  margin-top: 20px;
  white-space: pre-line;
}

.p {
  font-size: 18px;
  color: #838383;
  margin-top: 14px;
  white-space: pre-line;
  max-width: 500px;
}

.img_wrap {
  width: 100%;
}

.img_wrap img {
  width: 100%;
}

.scroll_wrap {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  row-gap: 4px;
  animation: bounce 0.35s ease infinite alternate;
}

@keyframes bounce {
  100% {
    bottom: 34px;
  }
}

@media screen and (max-width: 1000px) {
  .img_wrap {
    margin-top: 80px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    min-height: 100%;
  }
  .container {
    padding: 150px 20px 40px;
  }

  .title {
    font-size: 26px;
  }

  .subTitle {
    font-size: 18px;
  }

  .p {
    font-size: 16px;
  }

  .img_wrap {
    margin-top: 50px;
  }
}
