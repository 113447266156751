.wrapper {
  width: 100%;
  height: 100%;
  background-color: #252525;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1300px;
  padding: 140px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

/* 타이틀 박스 */
.title_wrap {
  width: 100%;
  display: flex;
}

.title_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.title {
  font-size: 40px;
  max-width: 700px;
  color: #fff;
  line-height: 1.25;
  white-space: pre-line;
  color: #e3416b;
}

/* 컨텐츠 시작 */
.contents_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.contents_container {
  overflow-x: auto;
  width: 100%;
  display: flex;
  padding: 0 20px;
  column-gap: 20px;
}

.contents_container::-webkit-scrollbar {
  display: none;
}

.contents_container li {
  display: flex;
  flex-direction: column;
}

.imgBox {
  min-height: 280px;
  width: 406px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgBox img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

/* ////////////==============//////////// */
.concept_wrap {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}

.concept_wrap h1 {
  font-size: 18px;
  width: auto;
  color: #fff;
  font-weight: 400;
  white-space: pre-line;
}

.concept_wrap span {
  color: #e3416b;
  margin-top: 10px;
  font-size: 13px;
  width: auto;
}

/* 버튼 */
.btn_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.btn_container a {
  font-size: 16px;
  padding: 16px 40px;
  border-radius: 12px;
  background-image: linear-gradient(98deg, #e3416b 0%, #e45f48 100%);
  border: 1.5px solid #e3416b;
  color: #fff;
  box-shadow: 0 5px 20px rgba(177, 91, 91, 0.4);
  transition: 0.1s ease-in-out;
  text-align: center;
}

.btn_container a:hover {
  background: transparent;
  color: #e3416b;
  box-shadow: none;
  font-weight: 500;
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 600px) {
  .container {
    padding: 140px 0 80px;
  }

  .title {
    font-size: 26px;
  }

  .p {
    font-size: 16px;
  }

  .imgBox {
    width: 100%;
    min-height: 240px;
    width: 320px;
  }

  .concept_wrap h1 {
    font-size: 16px;
    width: auto;
    color: #fff;
    font-weight: 400;
    white-space: pre-line;
  }

  .btn_container a {
    width: 100%;
    background: transparent;
    border: 1.5px solid #e3416b;
    color: #e3416b;
    box-shadow: none;
    font-weight: 500;
  }
}
