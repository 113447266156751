.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  width: 100%;
  height: 100%;
  flex: 1;
}

.wrapper_se2 {
  width: 100%;
  height: 100vh;
  color: #fff;
  font-size: 50px;
}
