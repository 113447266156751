@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,100&display=swap');

* {
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  max-width: 100%;
  width: 100%;
  color: #fff;
  background-image: linear-gradient(91deg, #e3416b 0%, #e45f48 100%);
  font-family: 'Noto Sans KR', sans-serif;
  overflow-y: scroll;
  color: #333;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #333;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #e2e2e2;
}
::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  width: 12px;
  height: 12px;
  background: #bbb;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  --webkit-transition: background-color 9999s ease-out;
  --webkit-box-shadow: 0 0 0px 1000px white inset !important;
  border-radius: 10px;
}

input:focus {
  outline: none;
}

input {
  background: transparent;
  border: none;
  outline: none;
}

textarea::placeholder,
input::placeholder {
  color: #ccc;
}

textarea {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  border: none;
  resize: none;
  font-family: 'Noto Sans KR', sans-serif;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
